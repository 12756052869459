import { useSearchParams, useNavigate, Navigate } from "react-router-dom";
import { useEffect } from "react";

//eslint-disable-next-line
const NaverLogin = ({ setGetToken, setUserInfo }) => {
  const { naver } = window;
  const NAVER_CLIENT_ID = "wPtEx1plJ8udj1_zFXfI";
  const NAVER_CALLBACK_URL = "http://localhost:3000";
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const initializeNaverLogin = () => {
    const naverLogin = new naver.LoginWithNaverId({
      clientId: NAVER_CLIENT_ID,
      callbackUrl: NAVER_CALLBACK_URL,
      // 팝업창으로 로그인을 진행할 것인지?
      isPopup: false,
      // 버튼 타입 ( 색상, 타입, 크기 변경 가능 )
      loginButton: { color: "green", type: 3, height: 58 },
      callbackHandle: true,
    });
    naverLogin.init();

    naverLogin.getLoginStatus(async function (status) {
      if (status) {
        // 아래처럼 선택하여 추출이 가능하고,
        // const userid = naverLogin.user.getEmail();
        const username = naverLogin.user.getName();
        localStorage.setItem("username", username);
        // 정보 전체를 아래처럼 state 에 저장하여 추출하여 사용가능하다.
        // setUserInfo(naverLogin.user)
      }
    });
  };

  const userAccessToken = () => {
    if (window.location.href.includes("access_token")) {
      getToken();
      searchParams.delete("access_token");
      setSearchParams(searchParams);
      navigate("/n");
    }
  };

  const getToken = () => {
    const token = window.location.href.split("=")[1].split("&")[0];
    localStorage.setItem("access_token", token);
  };

  useEffect(() => {
    initializeNaverLogin();
    userAccessToken();
  });

  return (
    <>
      <div id="naverIdLogin"> </div>
    </>
  );
};

const Home = () => {
  // return <NaverLogin />;
  return <Navigate to="/n" replace={true} />;
};

export default Home;
