import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
:root {
  --bg-color: #fff;
  --bg-transparent: rgba(240, 240, 240, 1);
  --border-color: #aaa;
  [contenteditable] {
  outline: 0px solid transparent;
  }

  body {
    background: #f7f1e5;
    -ms-overflow-style: none;
    ::-webkit-scrollbar {
      display: none;
    }
  }
  
  }
  div[align="center"] {
    text-align: center;
  }
  div[align="left"] {
    text-align: left;
  }
  div[align="right"] {
    text-align: right;
  }
  
  @keyframes smoothAppear {
    from {
      opacity: 0;
      transform: translateY(0%);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  --pale-navy: #4a4d76;

`;
export default GlobalStyle;
