import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import { styled, css } from "styled-components";
import Memo from "../components/Memo";
import Functions from "../components/Functions";

const background = css`
  ${(props) => css`
    background: ${props.theme.palette.navy};
  `}
`;

const NoteLayout = styled.div`
  ${background}
  height: calc(100vh-40px);
  overflow: scroll;
  animation: smoothAppear 1s;
  padding-right: 20px;
`;

const NoteHeaderFooter = styled.div`
  ${background}
  height: 20px;
  clear: both;
`;

const LineBarStyle = styled.div`
  --line-height: 25px;
  color: #aaaaaa;
  font-size: 10px;
  line-height: var(--line-height);
  background-size: 100% var(--line-height);
  width: 25px;
  overflow: hidden;
  white-space: pre-line;
  text-align: right;
  padding-right: 2px;
  float: left;
  display: inline-block;
`;

const NoteStyle = styled.div`
  --line-height: 25px;
  padding-left: 2px;
  padding-right: 2px;
  font-size: 12.5px;
  line-height: var(--line-height);
  border-left: 2px solid var(--border-color);
  border-right: 2px solid var(--border-color);

  // background: -webkit-linear-gradient(
  //   top,
  //   var(--border-color) 0%,
  //   var(--border-color) 1px,
  //   var(--bg-transparent) 1px,
  //   var(--bg-transparent) 100%
  // );
  // background: linear-gradient(
  //   to bottom,
  //   var(--border-color) 0%,
  //   var(--border-color) 1px,
  //   var(--bg-transparent) 1px,
  //   var(--bg-transparent) 100%
  // );
  background: linear-gradient(
    to bottom,
    #cccccc 0%,
    #cccccc 1px,
    white 1px,
    white 100%
  );
  background-size: 100% var(--line-height);
  width: 665px;
  min-height: 750px;
  float: left;
  display: inline-block;
  print-color-adjust: exact;
`;

function fromHTML(html, trim = true) {
  // Process the HTML string.
  html = trim ? html.trim() : html;
  if (!html) return null;

  // Then set up a new template element.
  const template = document.createElement("template");
  template.innerHTML = html;
  const result = template.content.children;

  // Then return either an HTMLElement or HTMLCollection,
  // based on whether the input HTML had one or more roots.
  if (result.length === 1) return result[0];
  return result;
}

function useContentEditableText({ initialNote }) {
  const contentEditable = useRef();
  const [note, _setNote] = useState(initialNote);

  const onInput = (e) => {
    _setNote({ ...note, content: e.target.innerHTML });
  };

  const onKeyUp = (e) => {
    if (e.target.innerText === "" || e.target.innerText === "\n") {
      // ctrl a delete 시 div 사라지는 문제 해결
      setContent("<div>&#8203;</div>"); // trick: 첫째줄 div 감싸기 위함
    }
    localStorage.setItem(`note-${initialNote.id}`, JSON.stringify(note));
  };

  const setContent = (newContent) => {
    if (contentEditable.current) {
      contentEditable.current.innerHTML = newContent;
      _setNote({ ...note, content: newContent });
    }
  };

  useLayoutEffect(() => {
    if (fromHTML(initialNote.content).innerText === "") {
      setContent("<div>&#8203;</div>");
    } else {
      setContent(initialNote.content);
    }
    // eslint-disable-next-line
  }, []);

  return { note, _setNote, setContent, onInput, onKeyUp, contentEditable };
}

const lineBarGenerator = (max_line) => {
  max_line = max_line < 30 ? 30 : max_line;
  var str = "";
  for (var i = 1; i <= max_line; i++) {
    if (i % 30 === 1) {
      var page = parseInt(i / 30) + 1;
      str += `(${page}쪽)`;
    } else if (i % 5 === 0) {
      str += i;
    }
    str += "\n";
  }
  return str;
};

function LineBar({ note, contentEditable }) {
  const [lineBarText, setlineBarText] = useState("");
  useEffect(() => {
    setlineBarText(
      lineBarGenerator(parseInt(contentEditable.current.clientHeight / 25)) //TODO: Hard coding
    );
  }, [note, contentEditable]);
  return <LineBarStyle>{lineBarText}</LineBarStyle>;
}

export const addNoteIfNotExistToLocalStorage = (initialNote) => {
  if (localStorage.getItem(`note-${initialNote.id}`) === null) {
    localStorage.setItem(`note-${initialNote.id}`, JSON.stringify(initialNote));
  }
};

function Note({ id }) {
  // api에서 id에 해당하는 note 객체 받아옴(없으면 만듬)
  // id는 있는데 다른 사용자의 note면 404 or forbidden
  // 받아오면 initialNote에다가 response를 덮어 씌우면 된다.
  // error면 return으로 처리!

  const initialNote = {
    id: parseInt(id),
    content: "<div>&#8203;</div>",
    memo: "",
  };

  const printRef = useRef();

  // userList에 해당 id 가진 note 없을 경우 return null
  // api로 가져온 note localstorage에 덮어씌우기
  addNoteIfNotExistToLocalStorage(initialNote);

  const loadNote = JSON.parse(localStorage.getItem(`note-${initialNote.id}`));
  const { note, _setNote, onInput, onKeyUp, contentEditable } =
    useContentEditableText({
      initialNote: loadNote,
    });
  //const lineBarText = lineBarGenerator(0);

  return (
    <>
      <Functions printRef={printRef} />
      <NoteLayout>
        <NoteHeaderFooter></NoteHeaderFooter>
        <div style={{ display: "flex" }} ref={printRef}>
          <LineBar note={note} contentEditable={contentEditable}></LineBar>
          <NoteStyle
            id="note"
            contentEditable
            ref={contentEditable}
            onInput={onInput}
            onKeyUp={onKeyUp}
          ></NoteStyle>
        </div>
        <NoteHeaderFooter></NoteHeaderFooter>
      </NoteLayout>
      <Memo note={note} setNote={_setNote} />
    </>
  );
}

export default Note;
