import React, { useState, useEffect, useRef } from "react";
import { styled, css } from "styled-components";
import { faStop, faPause, faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const StyledDiv = styled.div`
  ${(props) =>
    css`
      border: 1px ${props.theme.palette.lightNavy} solid;
    `}
  border-radius: 4px;
  margin: 10px auto;
  width: 100%;
  p {
    text-align: center;
  }
`;

function Stopwatch() {
  const [initialTime, setInitialTime] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [savedTime, setSavedTime] = useState(0);

  const [isRunning, setIsRunning] = useState(false);
  const interval = useRef();

  const formatTimeDelta = (timedelta) => {
    //eslint-disable-next-line
    const [ms, ss, mm, hh] = [
      parseInt(timedelta) % 100,
      parseInt(timedelta / 1000) % 60,
      parseInt(timedelta / (60 * 1000)) % 60,
      parseInt(timedelta / (60 * 60 * 1000)),
    ].map((x) =>
      x.toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })
    );
    return `${hh}:${mm}:${ss}`;
  };

  useEffect(() => {
    const time = new Date().getTime();
    setCurrentTime(time);
    setInitialTime(time);
  }, []);

  useEffect(() => {
    if (isRunning) {
      const time = new Date().getTime();
      setInitialTime(time);
      setCurrentTime(time);
      interval.current = setInterval(() => {
        setCurrentTime(new Date().getTime());
      }, 10);
    } else {
      clearInterval(interval.current);
      setSavedTime((t) => t + currentTime - initialTime);
      const time = new Date().getTime();
      setCurrentTime(time);
      setInitialTime(time);
    }
    // currentTime, initialTime은 !isRunning일 때 렌더링 되지 않으므로, deps에 넣기 불필요
    // eslint-disable-next-line
  }, [isRunning]);

  const onRun = () => {
    setIsRunning((state) => !state);
  };

  const onStop = () => {
    const time = new Date().getTime();
    setIsRunning(false);
    setCurrentTime(time);
    setInitialTime(time);
    setSavedTime(0);
  };

  return (
    <div>
      <StyledDiv>
        <p>{formatTimeDelta(currentTime - initialTime + savedTime)}</p>
        <p>
          <button onClick={onRun}>
            {isRunning ? (
              <FontAwesomeIcon icon={faPause} />
            ) : (
              <FontAwesomeIcon icon={faPlay} />
            )}
          </button>
          &nbsp;&nbsp;
          <button onClick={onStop}>
            <FontAwesomeIcon icon={faStop} />
          </button>
        </p>
      </StyledDiv>
    </div>
  );
}

export default Stopwatch;
