import { useCallback } from "react";
import Button from "./Button";
import {
  faAlignLeft,
  faAlignCenter,
  faAlignRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { styled } from "styled-components";

const ButtonGroup = styled.div`
  &:not(:first-child) {
    margin-top: 1rem;
  }
  display: flex;
  justify-content: space-between;
  button {
    width: 3rem;
  }
`;

function AlignButtons() {
  const alignDiv = useCallback(({ position }) => {
    const node = window.getSelection().baseNode;
    if (!node) return;
    if (node.nodeName === "#text") {
      const dom = node.parentElement;
      dom &&
        dom.parentElement.id === "note" &&
        dom.setAttribute("align", position);
    } else if ("DIV") {
      const dom = node;
      dom &&
        dom.parentElement.id === "note" &&
        dom.setAttribute("align", position);
    } else {
      return;
    }
    document.getElementById("note").focus();
  }, []);
  return (
    <ButtonGroup>
      <div>
        <Button
          color="lightNavy"
          onClick={() => {
            alignDiv({ position: "left" });
          }}
        >
          <FontAwesomeIcon icon={faAlignLeft}></FontAwesomeIcon>
        </Button>
      </div>
      <div>
        <Button
          color="lightNavy"
          onClick={() => {
            alignDiv({ position: "center" });
          }}
        >
          <FontAwesomeIcon icon={faAlignCenter}></FontAwesomeIcon>
        </Button>
      </div>
      <div>
        <Button
          color="lightNavy"
          onClick={() => {
            alignDiv({ position: "right" });
          }}
        >
          <FontAwesomeIcon icon={faAlignRight}></FontAwesomeIcon>
        </Button>
      </div>
    </ButtonGroup>
  );
}

export default AlignButtons;
