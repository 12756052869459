import { useEffect } from "react";
import { styled, css } from "styled-components";

const background = css`
  ${(props) => css`
    background: ${props.theme.palette.lightNavy};
  `}
`;

const StyledDiv = styled.div`
  @media (max-width: 1360px) {
    div {
      display: none;
    }
  }
  animation: smoothAppear 1s;
  ${background}
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  div {
    width: calc(100% - 60px);
    box-sizeing: border-box;
    margin: 20px auto;
    position: relative;
    label {
      font-size: 14px;
      padding: 10px;
      color: #333;
      font-weight: bold;
    }
    input {
      margin-left: -16px;
      width: 100%;
      border: 1px solid #999 !important;
      font-size: 1rem;
      line-height: 1.45;
      letter-spacing: -0.04rem;
      border-radius: 8px;
      padding: 16px;
      background: #ddd;
    }
    textarea {
      margin-left: -16px;
      width: 100%;
      height: 95%;
      border: 1px solid #999 !important;
      font-size: 0.8rem;
      line-height: 1.45;
      letter-spacing: -0.04rem;
      border-radius: 8px;
      padding: 16px;
      resize: none;
      background: #ddd;
    }
  }
`;

function Memo({ note, setNote }) {
  const onChange = (e) => {
    const { name, value } = e.target;
    setNote({ ...note, [name]: value });
  };

  useEffect(() => {
    localStorage.setItem(`note-${note.id}`, JSON.stringify(note));
  }, [note]);

  return (
    <StyledDiv>
      {/* <div>
        <input
          type="text"
          name="title"
          value={note.title}
          onChange={onChange}
          placeholder="제목"
        ></input>
      </div> */}
      <div style={{ flexGrow: 1 }}>
        <textarea
          name="memo"
          onChange={onChange}
          value={note.memo}
          placeholder="메모"
        ></textarea>
      </div>
    </StyledDiv>
  );
}

export default Memo;
