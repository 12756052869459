import { styled, css } from "styled-components";
import AlignButtons from "./AlignButtons";
import CharacterButtons from "./CharacterButtons";
import Stopwatch from "./Stopwatch";
// 백업기능 + 로그인 기능은 방학에 구현!
// eslint-disable-next-line
import Backup from "./Backup";
import { darken } from "polished";
import ReactToPrint from "react-to-print";

const background = css`
  ${(props) => css`
    background: ${darken(0.05, props.theme.palette.ivory)};
  `}
`;

const StyledDiv = styled.div`
  ${background}
  @media (max-width: 930px) {
    display: none;
  }
  width: 200px;
  display: flex;
  padding-top: 1rem;
  flex-direction: column;
  user-select: none;

  & > div {
    padding: 0 15px;
  }
`;

function Functions({ printRef }) {
  return (
    <StyledDiv>
      <AlignButtons />
      <CharacterButtons />
      <Stopwatch />
      <ReactToPrint
        trigger={() => <button>답안지 출력</button>}
        content={() => printRef.current}
      />
      {/* <Backup /> */}
    </StyledDiv>
  );
}

export default Functions;
