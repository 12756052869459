import styled from "styled-components";
import Button from "./Button";

const ButtonGroup = styled.div`
  &:not(:first-child) {
    margin-top: 1rem;
  }
  display: flex;
  justify-content: space-between;
`;

const ButtonCellDiv = styled.div`
  &:not(:first-child) {
    margin-top: 1rem;
  }
  button {
    width: 3rem;
  }
`;

function ButtonColumn({ characters }) {
  // characters : Array

  function insertTextAtCaret(text) {
    const sel = document.getSelection();
    const node = document.createTextNode(text);
    sel.getRangeAt(0).insertNode(node);
    sel.collapseToEnd();
  }

  return (
    <div>
      {characters.map((character, index) => (
        <ButtonCellDiv key={index}>
          <Button
            color="deepIvory"
            onClick={(e) => {
              document.getElementById("note").focus();

              insertTextAtCaret(character);

              document.getElementById("note").focus();
              // TODO: 글 마지막에 caret 두기
            }}
          >
            {character}
          </Button>
        </ButtonCellDiv>
      ))}
    </div>
  );
}

function CharacterButtons() {
  const charactersSet = {
    romeNumber: ["Ⅰ.", "Ⅱ.", "Ⅲ.", "Ⅳ.", "Ⅴ.", "Ⅵ.", "Ⅶ.", "Ⅷ.", "Ⅸ.", "Ⅹ."],
    circledNumber: ["①", "②", "③", "④", "⑤", "⑥", "⑦", "⑧", "⑨", "⑩"],
    etc: ["甲", "乙", "丙", "丁", "戊", "己", "「", "」", "判例", "·"],
  };
  return (
    <ButtonGroup>
      <ButtonColumn characters={charactersSet.romeNumber}></ButtonColumn>
      <ButtonColumn characters={charactersSet.circledNumber}></ButtonColumn>
      <ButtonColumn characters={charactersSet.etc}></ButtonColumn>
    </ButtonGroup>
  );
}

export default CharacterButtons;
