import { styled, css } from "styled-components";
import { darken } from "polished";

const StyledDiv = styled.div`
  ${(props) => css`
    background: ${darken(0.03, props.theme.palette.ivory)};
  `}
  width: 100%;
  display: flex;
  justify-content: space-around;
  p {
    text-align: center;
  }
  a {
    font-weight: 600;
    text-decoration: none;
    color: #666666;
    &:visited {
      text-decoration: none;
    }
    &:hover {
      color: #999999;
    }
  }
  span {
    font-weight: 600;
    text-decoration: none;
    color: #666666;
    &:visited {
      text-decoration: none;
    }
    &:hover {
      color: #999999;
    }
  }
  margin: 10px 0 0 0;
  padding: 2px;
  cursor: pointer;
`;

function LeftBarBottom({ onAboutClick }) {
  return (
    <StyledDiv>
      {/* <div>Login</div> */}
      <div>
        <span onClick={onAboutClick}>정보</span>
      </div>
      <div>
        <a href="mailto:law.dbnzi@gmail.com?subject=[DBNZI] 서비스 피드백">
          피드백
        </a>
      </div>
    </StyledDiv>
  );
}

export default LeftBarBottom;
