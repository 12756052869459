import React from "react";
import styled, { css } from "styled-components";
import { lighten, darken } from "polished";

const colorStyles = ({ theme, color }) => {
  const selected = theme.palette[color];
  return css`
    background: ${selected};
    &:hover {
      background: ${lighten(0.1, selected)};
    }
    &:active {
      background: ${darken(0.1, selected)};
    }

    ${(props) =>
      props.outline &&
      css`
        color: ${selected};
        background: none;
        border: 1px solid ${selected};
        &:hover {
          background: ${selected};
          color: white;
        }
      `}
    }
  `;
};

const sizes = {
  large: {
    height: "3rem",
    fontSize: "1.25rem",
  },
  medium: {
    height: "2.25rem",
    fontSize: "1rem",
  },
  small: {
    height: "1.75rem",
    fontSize: "0.875rem",
  },
  fixedSize: {
    height: "2.25rem",
    fontSize: "1rem",
  },
};

const sizeStyles = css`
  ${({ size }) => css`
    height: ${sizes[size].height};
    font-size: ${sizes[size].fontSize};
  `}
`;

const fullWidthStyle = css`
  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
      justify-content: center;
      &:not(:first-child) {
        margin-left: 0;
        margin-top: 1rem;
      }
    `}
`;

const StyledButton = styled.button`
  /* 공통 스타일 */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  border-radius: 4px;
  color: #555555;
  font-weight: bold;
  cursor: pointer;
  // padding-left: 1rem;
  // padding-right: 1rem;
  // margin-left: 5px;
  &:not(:first-child) {
    margin-left: 5px;
  }
  /* 크기 */
  ${sizeStyles};
  ${fullWidthStyle};
  /* 색상 */
  ${colorStyles};
`;

function Button({ children, ...rest }) {
  return <StyledButton {...rest}>{children}</StyledButton>;
}

Button.defaultProps = {
  color: "gray",
  size: "medium",
};

export default Button;
