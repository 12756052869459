import "react-notion/src/styles.css";
import { useState, useEffect } from "react";
import { NotionRenderer } from "react-notion";
import axios from "axios";

export default function ReactNotion({ notionPageId }) {
  const [response, setResponse] = useState({});

  useEffect(() => {
    axios
      .get(`https://notion-api.splitbee.io/v1/page/${notionPageId}`)
      .then(({ data }) => {
        setResponse(data);
      });
  });

  return (
    Object.keys(response).length && (
      <NotionRenderer blockMap={response} fullPage={true} />
    )
  );
}
