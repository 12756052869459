import { useNavigate } from "react-router-dom";
import { styled, css } from "styled-components";
import { darken } from "polished";

const StyledDiv = styled.div`
  ${(props) => css`
    background: ${darken(0, props.theme.palette.ivory)};
  `}
  text-align: center;
  width: 100%;
  padding: 8px 0;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    text-decoration: none;
    &:visited {
      text-decoration: none;
    }
    color: #888888;
  }
  cursor: pointer;
`;

const StyledSpan = styled.span`
  font-size: 36px;
  font-weight: 800;
  margin-left: 5px;
`;
const BetaSpan = styled.span`
  font-size: 18px;
  margon-left: 3px;
  color: #aaaaaa;
`;

function Banner() {
  const navigate = useNavigate();
  return (
    <StyledDiv
      onClick={() => {
        navigate("/");
      }}
    >
      <img
        src="/img/dbnzi-256px.png"
        width="40px"
        height="40px"
        alt="DBNZI LOGO"
      />
      <StyledSpan>DBNZI</StyledSpan>
      <BetaSpan>BETA</BetaSpan>
    </StyledDiv>
  );
}

export default Banner;
