import { Route, Routes } from "react-router-dom";
import Notes from "./pages/Notes";
import Home from "./pages/Home";
import About from "./pages/About";
import NotFound from "./pages/NotFound";
import NoteWrapper from "./pages/NoteWrapper";
function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/n" element={<Notes />}>
        <Route path=":id" element={<NoteWrapper />}></Route>
      </Route>
      <Route path="/about" element={<About />}></Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
