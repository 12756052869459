import Base from "../components/Base";
import { useOutlet, NavLink, useNavigate, useParams } from "react-router-dom";
import { styled, css } from "styled-components";
import { lighten, darken } from "polished";
import { faPlus, faTrash, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useReducer, useRef, useCallback, useEffect, useState } from "react";
import { addNoteIfNotExistToLocalStorage } from "./Note";
import Dialog from "../components/Dialog";
import Banner from "../components/Banner";
import LeftBarBottom from "../components/LeftBarBottom";
import Popup from "../components/Popup";
import ReactNotion from "../components/ReactNotion";

const addNotesIfNotExistToLocalStorage = (notes) => {
  if (localStorage.getItem("notes") === null) {
    localStorage.setItem("notes", JSON.stringify({ notes }));
  }
};

const changeNotesIfExistToLocalStorage = (notes) => {
  localStorage.setItem("notes", JSON.stringify({ notes }));
};

const ContainerDiv = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  user-select: none;
`;

const BlankDiv = styled.div`
  flex-grow: 1;
  ${(props) => css`
    background: ${props.theme.palette.lightNavy};
  `}
  display:flex;
  justify-content: center;
  align-items: center;
`;

const LeftBarDiv = styled.div`
  display: flex;
  flex-direction: column;
  background: #f7f1e5;
  @media (max-width: 1170px) {
    display: none;
  }
`;

const LeftMarginDiv = styled.div`
  display: none;
  @media (max-width: 1170px) {
    display: block;
  }
  flex-grow: 1;
  ${(props) => css`
    background: ${props.theme.palette.lightNavy};
  `}
`;

const StyledLi = styled.li`
  &:not(:first-child) {
    margin-top: 2px;
  }
`;

const NotesListDiv = styled.div`
  ul {
    padding: 1px 5px;
    margin-block-start: 1px;
  }
  flex-grow: 1;
  overflow: scroll;

  ${(props) => {
    return css`
      background: ${props.theme.palette.ivory};
      width: 250px;
      div.note-item {
        padding-left: 10px;
        padding-right: 10px;
        overflow: hidden;
        min-height: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        // align-items: center;
        &.note-add {
          color: white;
          background: ${darken(0, props.theme.palette.navy)};
          border-radius: 10px;
          cursor: pointer;
          &:hover {
            background: ${lighten(0.1, props.theme.palette.navy)};
            border-radius: 10px;
            cursor: pointer;
          }
          &:active {
            background: ${darken(0.1, props.theme.palette.navy)};
          }
        }
        & {
          background: ${darken(0, props.theme.palette.ivory)};
          border-radius: 10px;
          cursor: pointer;
          & div.note-delete {
            visibility: hidden;
          }
        }
        &:hover {
          background: ${lighten(0.1, props.theme.palette.ivory)};
          border-radius: 10px;
          cursor: pointer;
          & div.note-delete {
            display: flex;
            flex-direction: column;
            justify-content: center;
            visibility: visible;
            & svg:hover {
              color: #555555;
              // 글자 색깔
            }
          }
        }
        &:active {
          background: ${darken(0.1, props.theme.palette.ivory)};
        }
      }
      a {
        font-weight: 600;
        text-decoration: none;
        &:visited {
          text-decoration: none;
        }
        color: #888888;
        div {
          word-wrap: break-word;
          word-break: break-all;
        }
        // 글자 색깔

        &.selected div.note-item {
          background: ${props.theme.palette.yellow};
        }
      }
    `;
  }}
`;

function reducer(state, action) {
  switch (action.type) {
    case "CREATE_NOTE":
      return {
        ...state,
        notes: [action.note].concat(state.notes), // 역순으로 추가
      };
    case "DELETE_NOTE":
      return {
        ...state,
        notes: state.notes.filter((note) => note.id !== action.id),
      };
    case "ALTER_NOTES":
      return {
        ...state,
        notes: action.notes,
      };
    default:
      return state;
  }
}

function Notes() {
  const params = useParams();
  const urlId = params.id; // url 상의 Outlet에 해당하는 note의 id
  const navigate = useNavigate();
  const initialState = {
    notes: [{ id: 1, title: "새 답안지" }],
  };
  addNotesIfNotExistToLocalStorage(initialState.notes);

  const loadNotes = JSON.parse(localStorage.getItem("notes")).notes;
  const nextId = useRef(loadNotes.length === 0 ? 1 : loadNotes[0].id + 1);
  const [state, dispatch] = useReducer(reducer, { notes: loadNotes });

  const { notes } = state;

  useEffect(() => {
    // notes가 변경될때마다 localStorage에 저장
    changeNotesIfExistToLocalStorage(notes);
  }, [notes]);

  const outlet = useOutlet();

  const onCreate = useCallback(() => {
    dispatch({
      type: "CREATE_NOTE",
      note: {
        id: nextId.current,
        title: `새 답안지`,
      },
    });

    addNoteIfNotExistToLocalStorage({
      id: nextId.current,
      content: "<div>&#8203;</div>",
      memo: "",
    });
    navigate(`/n/${nextId.current}`);

    nextId.current += 1;

    // eslint-disable-next-line
  }, []);

  // delete Dialog에 관한 코드
  const initialDeleteDialog = {
    dialog: false,
    deleteNoteId: 0,
    deleteNoteTitle: "",
  };
  const [deleteDialog, setDeleteDialog] = useState(initialDeleteDialog);
  const onConfirmDelete = (id) => {
    setDeleteDialog({
      dialog: true,
      deleteNoteId: id,
      deleteNoteTitle: notes.find((note) => note.id === id).title,
    });
  };

  //modify Dialog에 관한 코드
  const initialModifyDialog = {
    dialog: false,
    modifyNoteId: 0,
    modifyNoteTitle: "",
  };
  const [modifyDialog, setModifyDialog] = useState(initialModifyDialog);
  const onConfirmModify = (id) => {
    setModifyDialog({
      dialog: true,
      modifyNoteId: id,
      modifyNoteTitle: notes.find((note) => note.id === id).title,
    });
  };
  const [titleInput, setTitleInput] = useState("");
  const onTitleInputChange = useCallback((e) => {
    const { value } = e.target;
    setTitleInput(value);
  }, []);

  //About Popup에 관한 코드
  const NOTION_PAGE_ID = "DBNZI-About-b09cc62077cc4f6c97fb9565baf46a77";
  const [about, setAbout] = useState(false);

  return (
    <>
      <Base>
        <ContainerDiv>
          <LeftBarDiv>
            <Banner />
            <NotesListDiv>
              <ul>
                <StyledLi>
                  <div className="note-item note-add" onClick={onCreate}>
                    <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                  </div>
                </StyledLi>
                {notes.map((note) => (
                  <StyledLi key={note.id} type="none">
                    <NavLink
                      to={`/n/${note.id}`}
                      className={({ isActive }) => (isActive ? "selected" : "")}
                    >
                      <div className="note-item">
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <div style={{ flexBasis: "80%" }}>{note.title}</div>
                          <div
                            className="note-delete"
                            style={{ textAlign: "right", flexBasis: "10%" }}
                          >
                            <FontAwesomeIcon
                              icon={faPen}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onConfirmModify(note.id);
                              }}
                            />
                          </div>
                          <div
                            className="note-delete"
                            style={{ textAlign: "right", flexBasis: "10%" }}
                          >
                            <FontAwesomeIcon
                              icon={faTrash}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onConfirmDelete(note.id);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </NavLink>
                  </StyledLi>
                ))}
              </ul>
            </NotesListDiv>
            <LeftBarBottom
              onAboutClick={() => {
                setAbout(true);
              }}
            />
          </LeftBarDiv>
          <LeftMarginDiv></LeftMarginDiv>
          {outlet || <BlankDiv>답안지를 선택하세요</BlankDiv>}
        </ContainerDiv>
        <Dialog
          title="정말로 삭제하시겠습니까?"
          confirmText="삭제"
          cancelText="취소"
          onCancel={() => {
            setDeleteDialog({
              ...deleteDialog,
              dialog: false,
              deleteNoteId: 0,
            });
          }}
          onConfirm={() => {
            if (!deleteDialog.dialog) return;
            dispatch({
              type: "DELETE_NOTE",
              id: deleteDialog.deleteNoteId,
            });
            setDeleteDialog(initialDeleteDialog);

            if (deleteDialog.deleteNoteId.toString() === urlId) {
              navigate("/n");
            }
          }}
          visible={deleteDialog.dialog}
        >
          "{deleteDialog.deleteNoteTitle}" 답안지를 정말로 삭제하시겠습니까?
        </Dialog>

        <Dialog
          title="제목 변경"
          confirmText="변경"
          cancelText="취소"
          onCancel={() => {
            setModifyDialog({
              ...modifyDialog,
              dialog: false,
              deleteNoteId: 0,
            });
          }}
          onConfirm={() => {
            if (!modifyDialog.dialog) return;
            dispatch({
              type: "ALTER_NOTES",
              notes: notes.map((note) =>
                note.id === modifyDialog.modifyNoteId
                  ? { ...note, title: titleInput }
                  : note
              ),
            });
            setModifyDialog(initialDeleteDialog);
          }}
          visible={modifyDialog.dialog}
          color="green"
        >
          <input
            autoFocus
            style={{ width: "90%" }}
            maxLength={50}
            id="modifyTitle"
            type="text"
            defaultValue={modifyDialog.modifyNoteTitle}
            onChange={onTitleInputChange}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                //위의 onConfirm에 들어가는 함수와 동일
                if (!modifyDialog.dialog) return;
                dispatch({
                  type: "ALTER_NOTES",
                  notes: notes.map((note) =>
                    note.id === modifyDialog.modifyNoteId
                      ? { ...note, title: titleInput }
                      : note
                  ),
                });
                setModifyDialog(initialDeleteDialog);
              }
            }}
          ></input>
        </Dialog>

        <Popup visible={about} setVisible={setAbout}>
          <ReactNotion notionPageId={NOTION_PAGE_ID} />
        </Popup>
      </Base>
    </>
  );
}

export default Notes;
