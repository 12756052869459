import ReactNotion from "../components/ReactNotion";
import Popup from "../components/Popup";
import { useState } from "react";

function About() {
  const NOTION_PAGE_ID = "DBNZI-About-b09cc62077cc4f6c97fb9565baf46a77";
  const [about, setAbout] = useState(true);

  return (
    <Popup visible={about} setVisible={setAbout}>
      <ReactNotion notionPageId={NOTION_PAGE_ID} />
    </Popup>
  );
}

export default About;
