import GlobalStyle from "../styles/GlobalStyle";
import { ThemeProvider } from "styled-components";

export const palette = {
  blue: "#228be6",
  gray: "#888888",
  pink: "#f06595",
  ivory: "#F7F1E5",
  deepIvory: "#E9DCCA",
  lightNavy: "#9796a4",
  navy: "#373a63",
  yellow: "#FFD965",
  green: "#03C03C",
  white: "#ffffff",
};

const Base = ({ children }) => {
  return (
    <>
      <ThemeProvider
        theme={{
          palette: palette,
        }}
      >
        <GlobalStyle />

        <div style={{ animation: "smoothAppear 0.5s" }}>{children}</div>
      </ThemeProvider>
    </>
  );
};
export default Base;
